<template>
	<el-dialog v-model="dialogVisible" :lock-scroll="true" title="" width="600px" top="15vh" :show-close="false">
		<div class="tips center">温馨提示:请将本通知打印加盖公章,并张贴在办理离职手续的场景。</div>
		<div class="content" ref="print">
			<!--startprint-->
			<div class="print center">
                <template v-if="submitMode!=3">
                    <img src="@/assets/imgs/leve-title.png" class="leve-title" alt="离职扫码通知">
                    <p class="text" v-if="submitMode==1">
                        为规范公司管理制度及控制用工风险 , 符合《劳动法》与《失业保险条例》规定 , 公司决定即日起 , 凡是与本司解除【劳动合同】的员工 , 在结算工资前 , 须扫描以下二维码填写【解除劳动合同通知书】,并由公司电子签章后以电子档发送至员工手机保存。
                    </p>
                    <p class="text" v-if="submitMode==2">
                        为规范公司人事管理中的离职登记制度，符合劳动法规要求，公司决定即日起，凡是从本公司离职的员工须扫描以下二维码授权开立【工作记录】，并由人事补充审核后以电子档形式发送至你的手机保存。
                    </p>
                </template>
                <template v-else>
                    <img src="@/assets/imgs/register-title.png" class="leve-title" alt="离职登记通知">
                    <p class="text">
                        为规范公司用工管理制度，便于本公司对离职情况进行管理，同时能够为离职人员提供更多的就业机会。自即日起，从本公司离岗的员工应扫描以下二维码，主动进行离职信息登记，提交到公司进行统一保存管理。
                    </p>
                </template>
                    <p class="fs_14 mt_10 color_333" style="text-align:left;margin-bottom:20px;">特此通知！</p>
				<div class="flex_end">
					<div>
						<p>{{ info.company_name }}</p>
						<p class="mt_10" style="text-align: right;">{{ info.date }}</p>
					</div>
				</div>
				<div class="line"></div>

				<div class="qr-code">
                    <div class="flex_cen logo">
                        <img :src="companyInfo.logo" alt="logo">

                    </div>
					<img :src="info.qrcode_url" alt="离职二维码">
				</div>
				<div class="fs_14 color_333 companyInfo">{{ info.company_name }}/{{ info.dept }}/{{ info.job }}</div>
				<div class="fs_12 color_999 flex_cen scan-tip"><img src="@/assets/imgs/icon/wechat.png" class="icon" alt="">请使用微信扫一扫功能或者职路微信小程序的扫码离职功能，扫描上方二维码</div>
			</div>
			<!--endprint-->
			<div class="utils flex_col no-print">
				<el-button type="primary" size="medium" @click="handlePrint">打印</el-button>
				<!-- <el-button type="primary" size="medium" @click="authBook">查看授权书</el-button> -->
				<el-button type="primary" size="medium" @click="dialogVisible=false">取消</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
//  1标准模式（离职证明） 2大厂模式（工作记录） 3地推模式（离职登记）
import print from 'td-print'
import { mapGetters } from "vuex"
export default {
	name: 'leveCodeDialog',
	data() {
		return {
			dialogVisible: false,
			info: {}
		}
	},
	props: {
		submitMode: {
			default: false,
			type: Boolean
		}
	},
	computed: {
		...mapGetters({
			companyInfo: 'getCompanyInfo'
		})
	},
	methods: {
		authBook() {
			this.dialogVisible = false
			this.$parent.$refs.authBook.dialogVisible = true
		},
		handlePrint() {
			print(this.$refs.print, {
				style: `<style>.print{margin-top:250px;}</style>`
			})
		}
	}
}
</script>

<style lang='less' scoped>
.tips {
	font-size: 14px;
	color: #fff;
	width: 100%;
	position: absolute;
	top: -50px;
	left: 0;
}
.companyInfo {
    display: inline-block;
    border: 1px solid #ededed;
    border-radius: 16px;
    padding: 2px 30px;
}
.scan-tip {
    width: 100%;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    .icon {
        width: 18px;
        height: auto;
        margin-right: 5px;
    }
}
.content {
	margin-top: -46px;
	padding: 20px;
	background: #d4d4d4;
	position: relative;
	/deep/.el-dialog__body {
		padding: 0;
	}
	.print {
		height: 780px;
		background: #fff;
		padding: 70px 45px;
		.leve-title {
			width: 223px;
			height: 38px;
			margin: 0 auto 23px;
		}
		.text {
			font-size: 14px;
			color: #333;
			line-height: 24px;
			text-indent: 28px;
			text-align: justify;
		}
		.line {
			border-top: 1px dashed #ededed;
			margin: 20px -45px 90px;
			position: relative;
			&::after,
			&::before {
				content: "";
				position: absolute;
				width: 15px;
				height: 15px;
				border-radius: 100%;
				background: #d4d4d4;
				top: -7px;
				z-index: 2;
			}
			&::after {
				left: -11px;
			}
			&::before {
				right: -11px;
			}
		}
		.qr-code {
            position: relative;
			padding: 3px;
			width: 200px;
			height: 200px;
			background: #f2f2f2;
            margin: 47px auto 13px;
            .logo {
                position: absolute;
                width: 46px;
                height: 46px;
                border-radius: 5px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 3;
                background: #fff;
                box-shadow: 0 0 6px 0 rgba(0, 0, 0, .2);
                overflow: hidden;
                img {
                    height: auto;
                }
            }
		}
	}
	.utils {
		position: absolute;
		right: -125px;
		top: 0;
		z-index: 99;
		.el-button {
			width: 110px;
			height: 42px;
			margin: 0 0 10px;
			font-size: 14px;
			background: #fff;
			color: #333;
            border-color: #fff;
            transition: all .2s ease;
			&:hover {
				background: #1890ff;
				color: #fff;
                border-color: #1890ff;
                transition: all .2s ease;
			}
		}
	}
}
</style>