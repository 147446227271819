<template>
    <div class="authbook">
        <el-dialog v-model="dialogVisible" :lock-scroll="true" :width="!auth_file ? '656px' : '30%'" :top="!auth_file?'20vh':'5vh'">
            <template v-slot:title>
                <div class="flex_c">
                    <p class="fs_18 bold">{{ title }}</p>
                </div>
            </template>
            <div class="content flex" v-if="!auth_file">
                <div>
                    <div class="tmp-img">
                        <img :src="tmpImg" alt="模板文件" />
                    </div>
                    <div class="flex_cen">
                        <p class="down-btn btn fs_14 color_999" @click="attachment">查看附件</p>
                    </div>
                </div>
                <el-upload class="upload-demo" drag :action="`${baseApi}/api/v2/upload/image?filename=file&type=company`" :headers="headers" multiple accept=".jpg,.jpeg,.png" :show-file-list="false" :on-success="handleLicenseSuccess">
                    <img :src="file_tmp" class="file_tmp" :alt="title" v-if="file_tmp" />
                    <div v-else>
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                            将文件拖到此处，或<em>点击上传</em>
                        </div>
                        <p class="center fs_12 info_color mt_10">
                            下载附件打印并盖章后拍照上传
                        </p>
                    </div>
                </el-upload>
            </div>
            <div class="content" :class="!auth_file ? '' : 'prove_pad'" v-else>
                <el-image :src="auth_file" class="leve-title" :alt="title"></el-image>
            </div>
            <template v-slot:footer v-if="!auth_file">
                <div class="flex_around">
                    <el-button type="primary" :disabled="!file_tmp" size="medium" @click="check">
                        提交{{title}}
                    </el-button>
                </div>
            </template>
        </el-dialog>
        <attachment-dialog ref="attachmentDialog"></attachment-dialog>
    </div>
</template>

<script>
import { removeToken, getToken } from "@/common/cache"
import attachmentDialog from './attachment-dialog.vue'
export default {
	name: 'authBook',
	components: { attachmentDialog },
	data() {
		return {
			auth_file: "",
			file_tmp: "",
			dialogVisible: false,
			baseApi: process.env.VUE_APP_API,
			headers: {
				Authorization: "Bearer " + getToken()
			}
		}
	},
	props: {
		title: {
			default: '',
			type: String
		},
		tmpImg: {
			default: '',
			type: String
		}
	},

	methods: {
		attachment() {
			this.dialogVisible = false
			window.scrollTo(0, 0)
			this.$refs.attachmentDialog.dialogVisible = true
			this.$nextTick(()=>{
				this.$refs.attachmentDialog.toimg()
			})
		},
		handleLicenseSuccess(res) {
			if (res.code !== 200) {
				if (res.code === 410000) {
					this.$message.error("请登录")
					removeToken()
					this.$router.push("/login")
				} else {
					this.$message.error(res.msg)
				}
			} else {
				this.file_tmp = res.data.url
			}
		},
		check() {
			if (!this.file_tmp) {
				this.$message.error(`请先上传${this.title}！`)
				return
			}
			this.$emit('upload', this.file_tmp)
		}
	}
}
</script>

<style lang="less" scoped>
@import '~@/assets/style/authBook-dialog.less';
</style>
