<template>
	<el-dialog v-model="dialogVisible" :lock-scroll="true" title="" width="600px" top="6vh" :show-close="false">
		<div class="content" ref="print">
			<!--startprint-->
			<div class="print center" ref="canvas">
                <template v-if="url">
                    <img :src="url" alt="职路数字" class="auth-file" style="margin-top:-14px" />
                </template>
                <template v-else>
                    <img src="@/assets/imgs/auth-file.png" alt="职路数字" class="auth-file" />
                    <img src="@/assets/imgs/yz.png" alt="印章" class="chapter" />
                    <p class="date year fs_12">{{ year }}</p>
                    <p class="date month fs_12">{{ month }}</p>
                    <p class="date day fs_12">{{ day }}</p>
                </template>
			</div>
			<!--endprint-->
			<div class="utils flex_col no-print">
				<el-button type="primary" size="medium" @click="handlePrint">打印</el-button>
				<el-button type="primary" size="medium" @click="dialogVisible=false">关闭</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
import print from 'td-print'
import html2canvas from 'html2canvas'
let year = new Date().getFullYear() // 年份
let month = new Date().getMonth() + 1 // 月份
let day = new Date().getDate() // 日期
export default {
	name: 'leveCodeDialog',
	data() {
		return {
			dialogVisible: false,
			year,
			month: month >= 10 ? month : '0' + month,
			day: day >= 10 ? day : '0' + day,
			url: ''
		}
	},

	methods: {
		handlePrint() {
			print(this.$refs.print, {
				style: `<style>.print{margin-top:480px;}</style>`
			})
		},
		toimg() {
			if(this.url)return
			var scale = 4
			var width = this.$refs.canvas.clientWidth
			var height = this.$refs.canvas.clientHeight
			//要将 canvas 的宽高设置成容器宽高的 4 倍
			var canvas = document.createElement("canvas")
			canvas.width = width * scale
			canvas.height = height * scale
			canvas.style.width = width + "px"
			canvas.style.height = height + "px"
			var context = canvas.getContext("2d")
			//然后将画布缩放，将图像放大两倍画到画布上
			context.scale(scale, scale)
			html2canvas(this.$refs.canvas, {
				useCORS: true,
				// allowTaint: true,
				scale: 1,
				canvas: canvas
			}).then((_canvas) => {
				context.mozImageSmoothingEnabled = false
				context.webkitImageSmoothingEnabled = false
				context.msImageSmoothingEnabled = false
				context.imageSmoothingEnabled = false

				let dataURL = _canvas.toDataURL("image/png")
				this.url = dataURL
			})
		}
	}
}
</script>

<style lang='less' scoped>
.content {
	margin-top: -46px;
	padding: 0;
	background: #d4d4d4;
	position: relative;
	/deep/.el-dialog__body {
		padding: 0;
	}
	.print {
		height: 850px;
		background: #fff;
        position: relative;
        overflow: hidden;
        .auth-file {
            height: auto;
        }
        .date {
            position: absolute;
            bottom: 250px;
            z-index: 3;
            transform: scale(.8);
        }
        .year {
            right: 203px;
        }
        .month {
            right: 172px;
        }
        .day {
            right: 136px;
        }
        .chapter {
            position: absolute;
            width: 112px;
            height: 112px;
            right: 110px;
            bottom: 244px;
            z-index: 5;
        }
	}
	.utils {
		position: absolute;
		right: -125px;
		top: 0;
		z-index: 99;
		.el-button {
			width: 110px;
			height: 42px;
			margin: 0 0 10px;
			font-size: 14px;
			background: #fff;
			color: #333;
            border-color: #fff;
            transition: all .2s ease;
			&:hover {
				background: #1890ff;
				color: #fff;
                border-color: #1890ff;
                transition: all .2s ease;
			}
		}
	}
}
</style>