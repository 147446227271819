<template>
    <div>
        <el-dialog v-model="dialogVisible" :lock-scroll="true" width="650px">
            <template v-slot:title>
                <div class="flex_c">
                    <p class="fs_18 bold">工作地点</p>
                </div>
            </template>
            <div class="content">
                <div class="center" v-if="addressOptions.length==0">
                    <el-image :src="require('@/assets/imgs/empty.png')" class="empty-img">
                        <template #placeholder>
                            <div class="image-slot">
                                加载中<span class="dot">...</span>
                            </div>
                        </template>
                    </el-image>
                    <p class="info_color center padding_20 fs_14">暂无工作地址</p>
                </div>
                <div v-else>
                    <div class="flex_c warning">
                        <p class="el-icon-warning fs_24 mr_5"></p>
                        <p>仅限以下地址3公里范围内扫码登记，超出范围无法提交离职登记</p>
                    </div>
                    <div class="flex padding_15 bgf fs_14 color_333 item" v-for="(item, index) in addressOptions" :key="index">
                        <p class="flex_1 one mr_10">{{item.curraddress}}</p>
                        <p class="color pointer" @click.stop="addWorkAddress(item.id)">编辑</p>
                    </div>
                    <div class="flex_cen padding_15" v-if="addressTotal>addressLimit">
                        <el-pagination class="page" background :page-size="addressLimit" :current-page="addressPage" layout="prev, pager, next" :total="addressTotal" @current-change="addressLoad"></el-pagination>
                    </div>
                </div>
            </div>
            <template v-slot:footer>
                <div class="flex_cen">
                    <el-button type="primary" size="medium" @click="addWorkAddress('')">新增地址</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
export default {
	data() {
		return {
			dialogVisible: false,
			addressOptions: [],
			addressLimit: 5,
			addressPage: 1,
			addressTotal: 0
		}
	},
	created() {
		this.getWorkAdr()
	},
	methods: {
		getWorkAdr() {
			this.$axios({
				url: '/ent/v3/working_place',
				methods: 'GET',
				data: {
					limit: this.addressLimit,
					page: this.addressPage
					// job_id: this.id
				},
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					if(res.data.total == 0) {
						this.$confirm("离职登记需在工作地点3公里范围内提交，去添加？", "暂无工作地点", {
							confirmButtonText: "确定",
							cancelButtonText: "取消",
							type: "warning"
						}).then(() => {
							this.dialogVisible = true
						})
					}
					this.$parent.hasAddress = res.data.total!=0
					this.addressTotal = res.data.total
					res.data.list.forEach(item => {
						item.curraddress = `${item.province}${item.city}${item.district}${item.address}${item.room_number}`
						item.seleted = false
						if (item.is_default == 1) {
							item.seleted = true
							this.address = item.curraddress
						}
					})

					this.addressOptions = res.data.list
				}
			})
		},
		addressLoad(page) {
			this.addressPage = page
			this.getWorkAdr()
		},
		addWorkAddress(id) {
			this.$parent.$refs.amap.mapVisible = true
			this.$parent.$refs.amap.id = id
			if (id) {
				this.$parent.$refs.amap.getMap()
			} else {
				this.$nextTick(() => {
					this.$parent.$refs.amap.initMap()
				})
			}
			this.dialogVisible = false
		}
	}
}
</script>

<style lang='less' scoped>
.warning {
	color: #ff9900;
    margin-bottom: 20px;
}
/deep/.el-dialog__header {
	padding: 20px;
}
/deep/.el-dialog__body {
	padding: 0;
}
.content {
	border-top: 1px solid #ddd;
	max-height: 400px;
	padding: 30px 75px;
    margin: 0 auto;
    .empty-img {
        width: 100px;
        margin: 0 auto;
    }
    .item {
        width: 500px;
        height: 44px;
        background: #F9F9F9;
        border: 1px solid #E3E3E3;
        margin-top: 10px;
        // &:hover {
        //     border-color: #409EFF;
        // }
    }
}
/deep/.el-button {
	width: 162px;
	height: 42px;
	font-size: 16px;
}
</style>