<template>
    <div class='contain' v-infinite-scroll="load" :infinite-scroll-disabled="finish">
        <searchBar @search="search"></searchBar>
        <div v-if="companyInfo.submit_mode!=3">
            <div class="tab flex bgf">
                <div class="left flex fs_16 color_999">
                    <p class="item btn" :class="{in:tab==0}" @click="authPath([0], changeTab)">待审清单<span class="badge">{{ tabNum.check_num || 0 }}</span></p>
                    <!-- <p class="item btn" :class="{in:tab==2}" @click="authPath([2], changeTab)">待匹配<span class="badge">{{ tabNum.wait_num || 0 }}</span></p> -->
                    <p class="item btn" :class="{in:tab==-1}" v-if="companyInfo.submit_mode==1" @click="authPath([-1], changeTab)">退回中<span class="badge">{{ tabNum.reject_num || 0 }}</span></p>
                    <p class="item btn" :class="{in:tab==1}" @click="authPath([1], changeTab)">离职清单<span class="badge">{{ tabNum.pass_num || 0 }}</span></p>
                </div>
                <div class="right flex fs_14 color_666">
                    <div class="item btn fs_14 flex_cen mr_20 color_999" @click="authPath(['authBook'], handleDialog)">
                        <p class="icon auth-icon"></p>授权书
                    </div>
                    <div class="item btn fs_14 flex_cen mr_20 color_999" @click="authPath(['leveCodeDialog'], handleDialog)">
                        <p class="icon qr-icon"></p>离职二维码
                    </div>
                    <div class="btn fs_14 flex_cen color_999 import-batch" @click="authPath([], bulkImport)">
                        <p class="import-icon"></p>批量导入 <span class="tip">NEW</span>
                    </div>
                </div>
            </div>

            <div v-show="tab==0 || tab==2">
                <list :status="tab" @get-tab-num="getTabNum" ref="list"></list>
            </div>
            <div v-show="tab==1||tab==-1">
                <back-list :status="tab" @get-tab-num="getTabNum" ref="backList"></back-list>
            </div>
            <!-- 授权书 -->
            <auth-book-dialog title="授权书" @upload="upload" :tmpImg="require('@/assets/imgs/auth-book.png')" ref="authBook"></auth-book-dialog>
        </div>
        <div class="register" v-else-if="companyInfo.submit_mode==3">
            <div class="tab flex bgf">
                <div class="left flex fs_16 color_999">
                    <p class="item btn in">已登记清单<span class="badge">{{ tabNum.check_num || 0 }}</span></p>
                </div>
                <div class="right flex fs_14 color_666">
                    <div class="item btn fs_14 flex_cen mr_20 color_999" @click="authPath([], showWorkAddress)">
                        <p class="icon dizhi-icon"></p>工作地点
                    </div>
                    <div class="item btn fs_14 flex_cen mr_20 color_999" @click="authPath(['leveCodeDialog'], handleDialog)">
                        <p class="icon qr-icon"></p>登记二维码
                    </div>
                </div>
            </div>
            <register-list ref="registerList"></register-list>
            <address-list ref="addressList"></address-list>
            <amap ref="amap" @callback="amapInit"></amap>
        </div>

        <!-- 离职二维码 -->
        <leve-code-dialog ref="leveCodeDialog" :submitMode="companyInfo.submit_mode"></leve-code-dialog>

        <el-backtop :bottom="100"></el-backtop>
    </div>
</template>

<script>
import list from './components/list.vue'
import Edit from './components/edit.vue'
import backList from './components/back-list.vue'
import registerList from './components/register-list.vue'
import authBookDialog from '@/components/authBook-dialog.vue'
import leveCodeDialog from './components/leveCode-dialog.vue'
import addressList from './components/address-list.vue'
import amap from '../position/components/amap'
import { mapGetters } from "vuex"
import { l_getItem } from "@/common/cache"
export default {
	name: 'Dimission',
	components: { list, Edit, backList, registerList, authBookDialog, leveCodeDialog, addressList, amap },
	data() {
		return {
			tab: 0,
			list: [],
			page: 1,
			limit: 10,
			finish: false,
			keywords: '',
			tabNum: {},
			qrcodeDetail: {},
			hasAddress: false
		}
	},
	created() {
		this.getTabNum()
		this.getQrcode()
	},
	computed: {
		...mapGetters({
			userInfo: "getUserInfo",
			companyInfo: "getCompanyInfo"
		})
	},

	methods: {
		getTabNum() {
			this.$axios({
				url: '/ent/v3/dimission/tab_num',
				method: 'GET',
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.tabNum = res.data
				}
			})
		},
		load() {
			if(this.companyInfo.submit_mode != 3) {
				if (this.tab == 0) {
					this.$refs.list.getAuditList()
				} else if (this.tab == 2) {
					this.$refs.list.getWaitList()
				} else if (this.tab == -1 || this.tab == 1) {
					this.$refs.backList.getList()
				}
			}else {
				this.$refs.registerList.getList()
			}
		},
		search(v) {
			this.keywords = v
			this.page = 1
			this.$refs.list.list = []
			this.$refs.backList.list = []
			this.finish = false
			this.load()
		},
		changeTab(v) {
			if (this.tab == v) return
			this.tab = v
			this.page = 1
			this.$refs.list.list = []
			this.$refs.backList.list = []
			this.finish = false
			if (this.tab == 0) {
				this.$refs.list.list = []
				this.$refs.list.getAuditList()
			} else if (this.tab == 2) {
				this.$refs.list.list = []
				this.$refs.list.getWaitList()
			} else if (this.tab == -1 || this.tab == 1) {
				this.$refs.backList.list = []
				this.$refs.backList.getList()
			}
			this.getTabNum()
		},
		handleDialog(dialog) {
			if(this.companyInfo.submit_mode == 3 && !this.hasAddress) {
				this.$confirm("当前未添加工作地址，是否添加？", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(() => {
					this.showWorkAddress()
				})
				return
			}
			if (this.userInfo.company_status !== 'normal') return
			// this.getQrcode(data => {
			if (dialog === 'leveCodeDialog') {
				this.$refs.leveCodeDialog.info = this.qrcodeDetail
			} else {
				this.$refs.authBook.auth_file = this.qrcodeDetail.auth_file
			}
			this.$refs[dialog].dialogVisible = true
			// })
		},
		getQrcode(cb) {
			let userInfo = l_getItem("USERINFO") || ''
			if (userInfo && userInfo.company_status !== 'normal') return
			this.$axios({
				url: '/ent/v3/dimission/qrcode',
				method: 'GET',
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.qrcodeDetail = res.data
					cb && cb(res.data)
				}
			})
		},
		bulkImport() {
			this.$router.push('/bulkImport/dimission')
		},
		upload(auth_file) {
			this.$axios({
				url: "/ent/v3/dimission/auth_file",
				method: "POST",
				data: {
					auth_file
				},
				shake: true
			}).then(res => {
				if (res.code === 200) {
					this.$message.success("上传成功")
					this.getQrcode()
					this.$refs.authBook.dialogVisible = false
				}
			})
		},
		amapInit() {
			this.$refs.addressList.getWorkAdr()
		},
		showWorkAddress() {
			this.$refs.addressList.dialogVisible = true
		}
	}
}
</script>

<style lang='less' scoped>
/deep/.el-dialog__body {
    padding: 0;
}
/deep/.amap {
    .el-dialog__body {
        padding: 30px 20px;
    }
}
.tab {
    margin-top: 0;
    .left {
        width: auto;
        .item {
            position: relative;
            margin-right: 100px;
            .badge {
                position: absolute;
                left: 100%;
                top: 18px;
                height: 14px;
                line-height: 14px;
            }
        }
    }
    .right {
        .item {
            transition: color 0.2s ease;
            &:hover {
                color: #1890ff !important;
                transition: color 0.2s ease;
                .auth-icon {
                    background: url(../../assets/imgs/icon/auth-icon1.png);
                    transition: all 0.2s ease;
                }
                .qr-icon {
                    background: url(../../assets/imgs/icon/qr-icon1.png);
                    transition: all 0.2s ease;
                }
                .dizhi-icon {
                    background: url(../../assets/imgs/icon/dizhi-icon1.png);
                    transition: all 0.2s ease;
                }
            }
        }
        .icon {
            width: 24px;
            height: 24px;
            background-size: 100% 100% !important;
            &.auth-icon {
                background: url(../../assets/imgs/icon/auth-icon.png);
                transition: all 0.2s ease;
            }
            &.qr-icon {
                background: url(../../assets/imgs/icon/qr-icon.png);
                transition: all 0.2s ease;
            }
            &.dizhi-icon {
                background: url(../../assets/imgs/icon/dizhi-icon.png);
                transition: all 0.2s ease;
            }
        }
    }
}


.mt_20 {
    margin-top: 20px;
}
</style>