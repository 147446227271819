<template>
    <div>
        <template v-if="list.length != 0">
            <!-- 离职审核处理提示 -->
            <div class="padding_10 mt_15 dimission-tip fs_14" v-html="$parent.tabNum.tip" v-show="$parent.tab==0&&list.length"></div>
            <div class="export" v-if="COMPANY.submit_mode == 2">
                <img src="@/assets/imgs/group-1.png" v-if="$parent.tab==0" class="group-btn btn" @click="authPath([], exportExcelData)" alt="">
            </div>
            <div class="list" v-for="(item, index) in list" :key="index">
                <div class="item bgf mt_10" v-if="!item.edit">
                    <div class="flex bottom_link top">
                        <div class="flex_c">
                            <el-avatar shape="square" :size="50" :src="item.avatar" class="mr_20">
                                <img :src="item.gender!=2?$store.state.nanAvatar:$store.state.nvAvatar" />
                            </el-avatar>
                            <p class="fs_16 bold color_333 mr_10">{{ item.realname }}</p>
                            <p class="fs_16 color_333 mr_20">{{ item.mobile }}</p>
                            <div class="flex_c" style="width:200px;" v-if="item.submit_mode !== 2">
                                <img src="@/assets/imgs/icon/id-icon.png" class="id-icon" alt="">
                                <p class="fs_14 color_666 mr_15">{{ !item.look?item.idcard_des:item.idcard }}</p>
                                <img src="@/assets/imgs/icon/off-icon.png" class="yanjing pointer" alt="未查看" v-if="!item.look" @click="changeLook(index)">
                                <img src="@/assets/imgs/icon/on-icon.png" class="yanjing pointer" alt="已查看" v-else @click="changeLook(index)">
                            </div>
                        </div>
                        <div class="fs_12 wait status-err flex_cen" v-if="status!=2"><span class="fs_36">·</span> &nbsp; 已等待 &nbsp; {{ item.usetime }}</div>
                        <div class="fs_12 color_999" v-else>{{ item.createtime }} 导入</div>
                        <div class="flex_c" style="width:240px" v-if="status!=2 && item.submit_mode!==2">
                            <div class="btn fs_14 center color_999" :class="{disabled:!item.allow_op}" @click="back(item, index)">退回</div>
                            <div class="btn fs_14 center color check" :class="{disabled:!item.allow_op}" @click="audit(item, index)">审核签章</div>
                        </div>
                        <div class="flex_c">
                            <div class="btn fs_14 center color_999" v-if="status==2" @click="delet(item, index)">删除</div>
                            <div class="flex_c" v-else>
                                <div class="btn fs_14 center color_999 close" @click="backout(item, index)">撤销授权</div>
                                <div class="btn fs_14 center color check" @click="edit(item, index)" v-if="item.submit_mode===2">审核</div>
                            </div>
                        </div>
                    </div>
                    <!-- v-if="status!=2" -->
                    <div class="bottom" v-if="item.submit_mode!==2">
                        <el-button type="info" class="edit-btn fs_14" @click="edit(item, index)" v-if="status!=2" :class="{disabled:!item.allow_op}">信息更正</el-button>
                        <el-row>
                            <el-col :span="6" class="fs_14">
                                <p class="flex_c">
                                    <span class="label sub_color">员工类型：</span>
                                    <span class="color_666">{{ item.type_text || '暂未填写' }}</span>
                                </p>
                                <p class="flex_c mt_10">
                                    <span class="label sub_color">所在部门：</span>
                                    <span class="color_666">{{ item.dept_name || '暂未填写' }}</span>
                                </p>
                            </el-col>
                            <el-col :span="6" class="fs_14">
                                <p class="flex_c">
                                    <span class="label sub_color">入职日期：</span>
                                    <span class="color_666">{{ item.start_time || '暂未填写' }}</span>
                                </p>
                                <p class="flex_c mt_10">
                                    <span class="label sub_color">担任职务：</span>
                                    <span class="color_666">{{ item.job_name || '暂未填写' }}</span>
                                </p>
                            </el-col>
                            <el-col :span="6" class="fs_14">
                                <p class="flex_c">
                                    <span class="label sub_color">离职日期：</span>
                                    <span class="color_666">{{ item.end_time || '暂未填写' }}</span>
                                </p>
                                <p class="flex_c mt_10">
                                    <span class="label sub_color">离职方式：</span>
                                    <span class="color_666">{{ item.method_text || '暂未填写' }}</span>
                                </p>
                            </el-col>
                            <el-col :span="24" class="fs_14 mt_10 flex_start">
                                <span class="label sub_color">工作内容：</span>
                                <div class="flex_1">
                                    <div class="color_666 fs_14 flex_1 job_content" :class="{hidden: !item.showMore}">
                                        <div class="color_666 fs_14" v-html="item.job_html" v-show="item.job_content"></div>
                                        <div class="color_666 fs_14" v-show="!item.job_content">未填写</div>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                        <div class="flex_cen" v-if="item.showMoreBtn">
                            <p class="color btn mt_15 fs_14" @click="showMore(index)">{{ !item.showMore?'展开更多':'收起' }}</p>
                        </div>
                    </div>
                    <!-- <div class="padding_15 flex_cen fs_14" v-else>
                        员工已授权办理离职， <p class="link_btn btn" @click="authPath([], bulkImport)">批量导入</p> 离职信息
                    </div> -->
                </div>
                <Edit :itemData="item" :index="index" @get-tab-num="getTabNum" :isOneCheck="item.submit_mode===2" v-else></Edit>
            </div>
        </template>

        <empty :finish="$parent.finish" :length="list.length" v-else>
            <div class="flex_cen">
                <el-button size="medium" plain @click="authPath(['leveCodeDialog'], $parent.handleDialog)">获取离职二维码</el-button>
            </div>
        </empty>

        <el-dialog v-model="dialogVisible" :lock-scroll="true" width="460px" top="20vh">
            <template v-slot:title>
                <div class="flex_c">
                    <p class="fs_18 bold">退回原因</p>
                </div>
            </template>
            <div class="content">
                <el-radio-group v-model="sendBack">
                    <el-row>
                        <el-col :span="8">
                            <el-radio label="身份证错误">身份证错误</el-radio>
                        </el-col>
                        <el-col :span="8">
                            <el-radio label="手机号错误">手机号错误</el-radio>
                        </el-col>
                        <el-col :span="8">
                            <el-radio label="在职时间错误">在职时间错误</el-radio>
                        </el-col>
                        <el-col :span="8" class="mt_20">
                            <el-radio label="查无此人">查无此人</el-radio>
                        </el-col>
                        <el-col :span="8" class="mt_20">
                            <el-radio label="部门职位错误">部门职位错误</el-radio>
                        </el-col>
                        <el-col :span="8" class="mt_20">
                            <el-radio label="other">其他原因</el-radio>
                        </el-col>
                    </el-row>
                </el-radio-group>
                <el-input placeholder="请填写退回原因" class="mt_20" v-model.trim="otherVal" v-if="sendBack=='other'"></el-input>
            </div>
            <template v-slot:footer>
                <div class="flex_around">
                    <el-button type="info" size="medium" @click="dialogVisible=false">取 消</el-button>
                    <el-button type="primary" size="medium" @click="submitBack">继 续</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import Edit from './edit.vue'
import cookie from 'js-cookie'
import {
	getToken, l_getItem
} from '@/common/cache'
import { mapGetters } from "vuex"

export default {
	components: { Edit },
	data() {
		return {
			COMPANY: {},
			exportApi: `${process.env.VUE_APP_API}/ent/v3/dimission/list?authorization=${getToken()}&down=1`,
			itemRow: '',
			itemIndex: 0,
			list: [],
			dialogVisible: false,
			sendBack: '',
			otherVal: '' // 其他退回原因
		}
	},
	props: {
		index: {
			default: 0,
			type: Number
		},
		status: {
			default: 0,
			type: Number
		}
	},
	created() {
		this.COMPANY = l_getItem('COMPANY')
		if (this.COMPANY && this.COMPANY.submit_mode != 3) {
			this.getAuditList()
		}
	},
	computed: {
		...mapGetters({
			companyInfo: "getCompanyInfo"
		})
	},
	methods: {
		getTabNum() {
			this.$emit('get-tab-num')
		},
		init(api) {
			this.$axios({
				url: `/ent/v3/dimission/${api}`,
				data: {
					page: this.$parent.page,
					limit: this.$parent.limit,
					keywords: this.$parent.keywords,
					status: this.$parent.tab
				},
				method: 'GET',
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.$parent.page++
					res.data.list.find(item => {
						item.look = false
						item.edit = false
						item.showMore = false
						item.showMoreBtn = false // 展开更多按钮
						if (item.job_content) {
							// 处理 textarea 空格和回车
							item.job_html = item.job_content.replace(/\n/g, "<br>").replace(/\s/g, "&nbsp;")
						}
					})
					this.list = this.list.concat(res.data.list)
					// this.list = this.arrFilter(this.list)
					this.$parent.finish = this.list.length >= res.data.total
					this.$nextTick(() => {
						let el = document.getElementsByClassName('job_content')
						el = Array.prototype.slice.call(el)
						el.forEach((elItem, index) => {
							if (elItem.offsetHeight >= 80) {
								this.list[index].showMoreBtn = true
							}
						})
					})
				} else {
					this.$parent.finish = true
				}
			})
		},
		// 导出数据
		exportExcelData() {
			const elink = document.createElement('a')
			elink.style.display = 'none'
			elink.href = this.exportApi
			document.body.appendChild(elink)
			elink.click()
			document.body.removeChild(elink)
		},
		/* 待审核列表 */
		getAuditList() {
			this.init('list')
		},
		/* 待匹配列表 */
		getWaitList() {
			this.init('wait_list')
		},
		/* 显示身份证号 */
		changeLook(index) {
			this.list[index].look = !this.list[index].look
		},
		/* 展开 */
		showMore(index) {
			this.list[index].showMore = !this.list[index].showMore
		},
		/* 编辑 */
		edit(item, index) {
			if (!item.allow_op) return
			this.list.find(row => {
				row.edit = false
			})
			this.list[index].edit = true
		},
		/* 退回 */
		back(item, index) {
			if (!item.allow_op) return
			this.dialogVisible = true
			this.itemRow = item
			this.itemIndex = index
		},
		submitBack() {
			this.$axios({
				url: `/ent/v3/dimission/check/${this.itemRow.id}`,
				method: 'PUT',
				data: {
					status: -1,
					reject: this.sendBack == 'other' ? this.otherVal : this.sendBack
				},
				shake: true
			}).then(res => {
				if (res.code === 200) {
					this.$message.success(`已退回 ${this.itemRow.realname} 的离职证明申请`)
					this.dialogVisible = false
					this.getTabNum()
					// this.$parent.tabNum.check_num--
					// this.$parent.tabNum.reject_num++
					this.list.splice(this.itemIndex, 1)
				}
			})
		},

		_confirm(item, index) {
			this.$confirm(`是否通过 <span class="color_000 bold">${item.realname}</span> 的离职证明申请?`, '提示', {
				dangerouslyUseHTMLString: true,
				confirmButtonText: '确 定',
				cancelButtonText: '取 消',
				type: 'warning'
			}).then(() => {
				this.$axios({
					url: `/ent/v3/dimission/check/${item.id}`,
					method: 'PUT',
					data: {
						status: 1
					},
					shake: true
				}).then(res => {
					if (res.code === 200) {
						this.$message.success(`已通过 ${item.realname} 的离职证明申请`)
						this.getTabNum()
						// this.$parent.tabNum.check_num--
						// this.$parent.tabNum.pass_num++
						this.list.splice(index, 1)
					}
				})
			})
		},
		/* 审核 */
		audit(item, index) {
			if (!item.allow_op) return
			if (!item.type_text || !item.dept_name || !item.start_time || !item.job_name || !item.end_time || !item.method_text) {
				this.$confirm("请完善员工信息后继续操作", "操作提示", {
					dangerouslyUseHTMLString: true,
					confirmButtonText: "确 定",
					showCancelButton: false,
					customClass: 'customConfirm',
					type: "warning"
				}).then(() => {

				})
				return
			}
			let auth_file = this.$parent.qrcodeDetail.auth_file || '' // 授权文件
			let authNotice = cookie.get('authNotice') || false // 授权提示

			if (!auth_file) {
				if (!authNotice) {
					this.$confirm("本企业暂未上传授权书，请确定离职签章已获得贵公司的授权", "操作提示", {
						dangerouslyUseHTMLString: true,
						confirmButtonText: "我已知晓",
						showCancelButton: false,
						customClass: 'customConfirm',
						type: "warning"
					}).then(() => {
						var expires = new Date()
						expires.setDate(expires.getDate() + 1)
						expires.setHours(0)
						expires.setMinutes(0)
						expires.setSeconds(0)
						cookie.set('authNotice', true, { expires: expires }) // 次日凌晨失效
						this._confirm(item, index)
					})
				} else {
					this._confirm(item, index)
				}
			} else {
				this._confirm(item, index)
			}
		},
		/* 删除 */
		delet(item, index) {
			this.$confirm(`确认删除 <span class="color_000 bold">${item.realname}</span> 的离职证明申请?`, '提示', {
				dangerouslyUseHTMLString: true,
				confirmButtonText: '确 定',
				cancelButtonText: '取 消',
				type: 'warning'
			}).then(() => {
				this.$axios({
					url: `/ent/v3/dimission/wait_list/${item.id}`,
					method: 'DELETE',
					shake: true
				}).then(res => {
					if (res.code === 200) {
						this.getTabNum()
						// this.$parent.tabNum.wait_num--
						this.list.splice(index, 1)
						this.$message.success(`删除成功`)
					}
				})
			})
		},
		// 撤销授权
		backout(item, index) {
			this.$confirm(`确认撤销 <span class="color_000 bold">${item.realname}</span> 的离职授权?`, '提示', {
				dangerouslyUseHTMLString: true,
				confirmButtonText: '确 定',
				cancelButtonText: '取 消',
				type: 'warning'
			}).then(() => {
				this.$axios({
					url: `/ent/v3/dimission/cancel/${item.id}`,
					method: 'PUT',
					shake: true
				}).then(res => {
					if (res.code === 200) {
						this.getTabNum()
						// this.$parent.tabNum.check_num--
						this.list.splice(index, 1)
						this.$message.success(`授权撤销成功`)
					}
				})
			})
		},
		/* 编辑保存后初始化列表 */
		saveAfterInit() {
			this.$parent.page = 1
			this.$parent.finish = false
			this.list = []
			this.getAuditList()
		},
		bulkImport() {
			this.$router.push('/bulkImport/dimission')
		}
	}
}
</script>
<style>
.customConfirm {
    width: 460px !important;
}
</style>
<style lang='less' scoped>

.dimission-tip {
    background: #fdf7d3;
    border: 1px solid #ffea63;
}
.list {
    position: relative;
}
.export {
    position: relative;
    .group-btn {
        position: absolute;
        width: 36px;
        height: auto;
        top: 10px;
        right: -18px;
        z-index: 3;
    }
}

.item {
    border-radius: 2px;
    .top {
        padding: 30px 30px 20px;
        .avatar {
            width: 50px;
            height: 50px;
            margin-right: 20px;
            border-radius: 2px;
        }
        .yanjing {
            width: 14px;
        }
        .id-icon {
            width: 24px;
            margin-right: 5px;
        }
        .btn {
            width: 100px;
            height: 32px;
            line-height: 32px;
            border: 1px solid #ccc;
            border-radius: 2px;
            margin-left: 20px;
            padding: 0;
            &.el-button--defalut {
                &:focus, &:hover {
                    background: #fff;
                }
            }
            &.check {
                border-color: #1890ff;
            }
            &.close {
                border: none;
                text-decoration: underline;
            }
        }
        .el-button {
            width: 100px;
            height: 32px;
        }
    }
    .bottom {
        padding: 20px 30px 30px;
        position: relative;
        .edit-btn {
            position: absolute;
            top: 0;
            right: 30px;
            z-index: 5;
            width: 100px;
            height: 30px;
            border-radius: 0 0 15px 15px;
            transition: all 0.2s ease;
            &:hover {
                background: #5ab0ff;
                border-color: #5ab0ff;
                color: #fff;
                transition: all 0.2s ease;
            }
        }
        .label {
            width: 80px;
        }
        /deep/.el-collapse,
        /deep/.el-collapse-item__header {
            border: none;
        }

        .job_content {
            &.hidden {
                max-height: 80px;
                overflow: hidden;
            }
        }
    }
    .disabled {
        background-color: #f9f9f9 !important;
        color: #cdcdcd !important;
        border: none !important;
    }

    .status-err {
        color: #ff4e4e;
    }
    .status-warn {
        color: #ffb350;
    }
    .status-succ {
        color: #56d971;
    }
}
/deep/.el-dialog__header {
    padding: 20px;
}
/deep/.el-dialog__body {
    padding: 0;
}
.content {
    border-top: 1px solid #ddd;
    height: 168px;
    padding: 30px;
}
/deep/.el-button {
    width: 162px;
    height: 42px;
    font-size: 16px;
}
</style>