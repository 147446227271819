<template>
    <div class="list">
        <template v-if="list.length != 0">
            <div class="item bgf mt_10" v-for="(item, index) in list" :key="index">
                <div class="flex">
                    <div class="flex_c">
                        <el-avatar shape="square" :size="50" :src="item.avatar" class="mr_20">
                            <img :src="item.gender=='男'?$store.state.nanAvatar:$store.state.nvAvatar" />
                        </el-avatar>
                        <div class="flex_1">
                            <div class="flex_c">
                                <p class="fs_20 bold color_333 mr_20">{{ item.realname }}</p>
                                <p class="fs_16 color_333 mr_20">{{ item.mobile }}</p>
                                <p class="fs_12 btn leve color" v-if="item.cert_pic" @click.stop="reportDetail(item.cert_pic)">
                                    离职登记
                                </p>
                            </div>

                            <p class="use_tip mt_5 fs_14">
                                <span class="line">{{ item.type_text }}</span>
                                <span class="line">工作岗位：{{ item.job_name }}</span>
                            </p>
                        </div>
                    </div>
                    <div class="flex_c">
                        <div class="fs_12 sub_color info center">
                            <p>入职日期</p>
                            <p class="color_999 mt_10">{{ item.start_time }}</p>
                        </div>
                        <div class="fs_12 sub_color info center">
                            <p>离职日期</p>
                            <p class="color_999 mt_10">{{ item.end_time }}</p>
                        </div>
                        <div class="fs_12 sub_color info center">
                            <p>登记时间</p>
                            <p class="color_999 mt_10">{{ item.detime }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <empty :finish="$parent.finish" :length="list.length" v-else></empty>
        <!-- 离职证明申请 、 工作记录 -->
        <prove ref="prove"></prove>
    </div>
</template>

<script>
import prove from "../../talents-hall/dialog/prove"
import { l_getItem } from "@/common/cache"
export default {
	components: { prove },
	data() {
		return {
			list: []
		}
	},
	created() {
		let COMPANY = l_getItem('COMPANY')
		if(COMPANY && COMPANY.submit_mode == 3) {
			this.getList()
		}
	},
	methods: {
		getList() {
			this.$axios({
				url: `/ent/v3/dimission/list`,
				data: {
					page: this.$parent.page,
					limit: this.$parent.limit,
					keywords: this.$parent.keywords,
					status: 1
				},
				method: "GET",
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.$parent.page++
					res.data.list.find(item => {
						if (parseInt(item.idcard.substr(16, 1)) % 2 == 1) {
							item.gender = '男'
						} else {
							item.gender = '女'
						}
					})
					this.list = this.list.concat(res.data.list)
					this.$parent.tabNum.check_num = res.data.total
					this.$parent.finish = this.list.length >= res.data.total
				} else {
					this.$parent.finish = true
				}
			})
		},
		reportDetail(pic) {
			if (!pic) return
			this.$refs.prove.pic = pic
			this.$refs.prove.init()
			this.$refs.prove.dialogVisible = true
		}
	}
}
</script>

<style lang="less" scoped>
.item {
    height: 110px;
    border-radius: 2px;
    border: 1px solid #ededed;
    padding: 30px 0 30px 30px;
    .avatar {
        width: 50px;
        height: 50px;
        margin-right: 20px;
    }
    .info {
        // padding: 0 50px;
        width: 200px;
        &.name {
            width: 150px;
        }
        &.del {
            width: auto;
            padding: 0 50px;
        }
    }
    .info:not(:last-child) {
        border-right: 1px solid #ededed;
    }
    .btn {
        transition: color 0.2s ease;
        &:hover {
            color: #1890ff;
            transition: color 0.2s ease;
        }
    }
    .btn.leve {
        border: 1px solid #1890ff;
        border-radius: 2px;
        padding: 0 3px;
    }
    .btn.dis {
        border: 1px solid #ededed;
        border-radius: 2px;
        padding: 0 3px;
        cursor: auto;
        transition: color 0.2s ease;
        &:hover {
            color: #333;
            transition: color 0.2s ease;
        }
    }
}
</style>
