<template>
    <div class="item bgf mt_10">
        <el-form :model="ruleForm" :hide-required-asterisk="true" :rules="rules" ref="ruleForm" class="form">
            <div class="flex top">
                <div class="flex_c">
                    <el-avatar shape="square" :size="50" :src="ruleForm.avatar" class="mr_20">
                        <img :src="ruleForm.gender!=2?$store.state.nanAvatar:$store.state.nvAvatar" />
                    </el-avatar>
                    <p class="fs_16 bold color_333 mr_10">{{ ruleForm.realname }}</p>
                    <p class="fs_16 color_333 mr_20">{{ ruleForm.mobile }}</p>
                    <div class="flex_c" style="width:200px;">
                        <img src="@/assets/imgs/icon/id-icon.png" class="id-icon" alt="">
                        <p class="fs_14 color_666 mr_15">{{ !ruleForm.look?ruleForm.idcard_des:ruleForm.idcard }}</p>
                        <img src="@/assets/imgs/icon/off-icon.png" class="yanjing pointer" alt="未查看" v-if="!ruleForm.look" @click="changeLook">
                        <img src="@/assets/imgs/icon/on-icon.png" class="yanjing pointer" alt="已查看" v-else @click="changeLook">
                    </div>
                </div>
                <div class="flex_c">
                    <div class="btn fs_14 center color_999" style="line-height: 32px;" @click="cancel">取 消</div>
                    <el-button type="primary" class="btn fs_14 center color check txt" @click="submitForm('ruleForm')">{{isOneCheck?'确 定':'保 存'}}</el-button>
                </div>
            </div>
            <div class="bottom">
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="员工类型：" prop="type" class="mr_20">
                            <el-radio-group v-model="ruleForm.type">
                                <el-radio label="in" style="margin-right:16px;">正式工</el-radio>
                                <el-radio label="out">非正式工</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="isOneCheck">
                        <el-form-item label="" prop="idcard" class="mr_20 date-picker">
                            <el-input placeholder="请填写身份证" v-model.trim="ruleForm.idcard">
                                <template #prefix>
                                    <span>&nbsp;&nbsp;身份证：</span>
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="入职日期：" prop="start_time" class="mr_20 date-picker">
                            <div class="flex">
                                <el-date-picker v-model="ruleForm.start_time" prefix-icon="false" type="date" :editable="false" :disabledDate="disabledDate" :clearable="false" class="value flex_1 datetime" placeholder="请选择入职日期"></el-date-picker>
                                <i class="el-icon-date fs_18"></i>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="离职日期：" prop="end_time" class="mr_20 date-picker">
                            <div class="flex">
                                <el-date-picker v-model="ruleForm.end_time" prefix-icon="false" type="date" :editable="false" :disabledDate="disabledDate" :clearable="false" class="value flex_1 datetime" placeholder="请选择离职日期"></el-date-picker>
                                <i class="el-icon-date fs_18"></i>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="" prop="dept_name" class="mr_20 date-picker">
                            <el-input placeholder="请填写所在部门" v-model.trim="ruleForm.dept_name">
                                <template #prefix>
                                    <span>&nbsp;&nbsp;所在部门：</span>
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="" prop="job_name" class="mr_20 date-picker">
                            <el-input placeholder="请填写担任职务" v-model.trim="ruleForm.job_name">
                                <template #prefix>
                                    <span>&nbsp;&nbsp;担任职务：</span>
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="离职方式：" prop="method" class="mr_20">
                            <el-radio-group v-model="ruleForm.method">
                                <el-radio :label="0" style="margin-right:16px;">协商解除</el-radio>
                                <el-radio :label="1">劳动者单方解除</el-radio>
                                <el-radio :label="2">用人单位单方解除</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="" class="mr_20 date-picker">
                            <el-input placeholder="请填写工作内容" type="textarea" rows="4" v-model.trim="ruleForm.job_content"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
        </el-form>
    </div>
</template>

<script>
import { isIdCard } from '@/common/validate'

export default {
	data() {
		var checkIdcard = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入身份证号'))
			} else if (!isIdCard(value)) {
				callback(new Error('请输入正确的身份证号'))
			} else {
				callback()
			}
		}
		return {
			radio: '',
			ruleForm: {},
			rules: {
				type: [
					{ required: true, message: '请选择员工类型', trigger: 'change' }
				],
				start_time: [
					{ required: true, message: '请选择入职日期', trigger: 'change' }
				],
				end_time: [
					{ required: true, message: '请选择离职日期', trigger: 'change' }
				],
				dept_name: [
					{ required: true, message: '请填写所在部门', trigger: 'change' }
				],
				idcard: [
					{ validator: checkIdcard, trigger: 'blur' }
				],
				job_name: [
					{ required: true, message: '请填写担任职位', trigger: 'change' }
				],
				method: [
					{ required: true, message: '请填选择离职方式', trigger: 'change' }
				]
			},
			disabledDate: (time) => {
				return time.getTime() > new Date().getTime()
			}
		}
	},
	props: {
		itemData: {
			default: () => {},
			type: Object
		},
		index: {
			default: 0,
			type: Number
		},
		isOneCheck: {
			default: false,
			type: Boolean
		}
	},
	created() {
		this.ruleForm = JSON.parse(JSON.stringify(this.itemData))
	},
	watch: {
		itemData(n) {
			this.ruleForm = JSON.parse(JSON.stringify(n))
		}
	},
	methods: {
		cancel() {
			this.$parent.list[this.index].edit = false
		},
		changeLook() {
			this.ruleForm.look = !this.ruleForm.look
		},
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					if(new Date(this.ruleForm.start_time).getTime() > new Date(this.ruleForm.end_time).getTime()){
						this.$message.warning('入职日期不能大于离职日期')
						return
					}
					this.$axios({
						url: `/ent/v3/dimission/save/${this.ruleForm.id}`,
						method: 'PUT',
						shake: true,
						data: this.ruleForm
					}).then(res=>{
						if(res.code === 200) {
							if(this.isOneCheck) {
								// 单个审核
								this.dimissionCheck()
							}else {
								this.cancel()
								this.$parent.saveAfterInit()
							}
						}
					})
				}
			})
		},
		dimissionCheck() {
			this.$axios({
				url: `/ent/v3/dimission/check/${this.ruleForm.id}`,
				method: 'PUT',
				data: {
					status: 1 // 通过审核
				}
			}).then(res => {
				if (res.code === 200) {
					this.$message.success(`已通过 ${this.ruleForm.realname} 的离职申请`)
					this.cancel()
					this.$emit('get-tab-num')
					this.$parent.saveAfterInit()
				}
			})
		}
	}
}
</script>

<style lang='less' scoped>
.item {
    border-radius: 2px;
    border: 1px solid #1890ff;
    .top {
        padding: 30px 30px 20px;
        .avatar {
            width: 50px;
            height: 50px;
            margin-right: 20px;
            border-radius: 2px;
        }
        .id-icon {
            width: 24px;
            margin-right: 5px;
        }
        .yanjing {
            width: 14px;
        }
        .btn {
            width: 100px;
            height: 32px;
            border: 1px solid #ccc;
            border-radius: 2px;
            margin-left: 20px;
            &.check {
                border-color: #1890ff;
            }
        }
    }
    .bottom {
        background: #f8f8f8;
        padding: 20px 30px 30px;
        /deep/.el-input__inner {
            padding-left: 80px;
        }
        /deep/.el-date-editor.el-input,
        .el-date-editor.el-input__inner {
            width: 100%;
        }
        /deep/.date-picker {
            .el-form-item__label {
                position: absolute;
                z-index: 2;
                left: 10px;
                color: #c0c4cc;
            }
            .el-icon-date {
                margin-left: -25px;
                position: relative;
                z-index: 2;
                color: #ccc;
            }
        }
        /deep/.el-form-item__label {
            color: #ccc;
        }
    }
}
</style>